<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    <Wrapper class="max-w-2xl">
      <h2 class="text-left text-3xl text-blue-dark py-2">
        <b>Formulario para presentar una inconformidad</b>
      </h2>
      <p class="mt-6 mb-4 text-left text-xs text-gray-400">
        Complete los campos solicitados y la información detallada.
      </p>

      <div v-if="customer">
        <h2 class="my-5 text-2xl text-left text-blue">
          <b>Datos de contacto</b>
        </h2>
        <div class="grid grid-cols-2 gap-4 w-full">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="customer.person_type"
            :disabled="disabled"
          />
        </div>

        <CustomerNatural
          v-if="customer && customer.person_type == 1"
          :customer="customer"
        />
        <CustomerLegal
          v-if="customer && customer.person_type == 2"
          :customer="customer"
        />
        <ComplaintForm
          :customer="customer"
        />
        <a-modal
          v-model="modalInfoVisible"
          centered
          :footer="null"
          :closable="true"
          @ok="() => (modalInfoVisible = false)"
        >
          <h1 class="mb-6 text-xl text-left text-blue"><b>Recuerde...</b></h1>
          <a-timeline>
            <a-timeline-item>
              En caso de que su inconformidad esté relacionada con un reporte de operadores 
              o centrales de información, es necesario que haya presentado una queja ante la entidad 
              vigilada o su Defensor del Consumidor Financiero.
            </a-timeline-item>
            
            <a-timeline-item
              >La información del Consumidor Financiero es indispensable para
              erradicar la inconformidad..</a-timeline-item
            >
            <hr class="my-4" />
            <!-- <p class="text-justify italic">
              De conformidad con lo dispuesto en la Ley 1581 de 2012, el Decreto
              1377 de 2013 y el Decreto 1074 de 2015, autorizo a la
              Superintendencia Financiera de Colombia (SFC) a realizar el
              tratamiento de los datos personales y/o sensibles necesario para
              el ejercicio de sus facultades, de conformidad con lo establecido
              en la Política de Tratamiento de datos de la SFC disponible en el
              siguiente
              <a
                class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                target="_blank"
                href="https://www.superfinanciera.gov.co/publicaciones/10106783/nuestra-entidadacerca-de-la-sfcpoliticas-de-la-entidadpoliticas-web-10106783/"
                >link</a
              >. El texto completo de la autorización se encuentra disponible en
              el siguiente
              <a
                href="https://www.superfinanciera.gov.co/loader.php?lServicio=Tools2&lTipo=descargas&lFuncion=descargar&idFile=1070896"
                class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                >link</a
              >.
            </p> -->
            <!-- <form
              @submit.prevent="authorizePersonalDataProcessing"
              class="w-full px-0 py-0"
            >
              <div class="w-full flex justify-center">
                <div class="grid grid-cols-2 gap-4 w-full">
                  <div class="flex justify-center w-full">
                    <BaseRadio
                      class="w-full"
                      ref="personalData"
                      name="personalData"
                      id="personalData"
                      label="¿Autorizo el tratamiento de datos personales?"
                      :radioOptions="optionsYN"
                      v-model="checkPersonalData"
                      :required="true"
                    />
                  </div>
                  <br />
                  <div class="flex justify-center">
                    <BaseRadio
                      ref="sensitiveData"
                      name="sensitiveData"
                      id="sensitiveData"
                      label="¿Autorizo el tratamiento de datos sensibles?"
                      :radioOptions="optionsYN"
                      v-model="checkSensitiveData"
                      :required="true"
                    />
                  </div>
                </div>
              </div>
              <br />

              <p v-if="feedback" class="text-xs text-center text-red-500">
                {{ feedback }}
              </p>

              <div class="flex justify-center mt-3 gap-4">
                <BaseButton
                  type="submit"
                  id="sendComplaint"
                  label="ACEPTAR"
                  class="w-1/3"
                />
                <a-button
                  class="w-1/3"
                  type="danger"
                  shape="round"
                  :block="true"
                  ghost
                  @click="onCancelModal"
                >
                  CANCELAR
                </a-button>
              </div>
            </form> -->
          </a-timeline>
        </a-modal>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ComplaintForm from "@/components/Customer/Complaint/ComplaintForm";
import CustomerNatural from "@/components/Dashboard/Customers/CustomerNatural";
import CustomerLegal from "@/components/Dashboard/Customers/CustomerLegal";

export default {
  components: {
    ComplaintForm,
    CustomerLegal,
    CustomerNatural,
  },
  data() {
    return {
      customer: {},
      loading: false,
      disabled: false,
      modalInfoVisible: false,
      personalDataAuthorized: false,
      checkSensitiveData: 2,
      checkPersonalData: 2,
      feedback: null,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
    };
  },
  async created() {
    const { identification_type, identification_number } = this.$route.query;

    this.loading = true;
    let { data, error } = await this.$api.getQueryPerson(
      `?identification_number=${identification_number}&identification_type=${identification_type}`
    );
    this.loading = false;

    if (error) {
      this.showToast(
        "error",
        "No se encontró un usuario para esa identificación."
      );
      return;
    }
    this.disabled = true;

    if (data) {
      if (data.results.length === 0) {
        this.showToast(
          "error",
          "No se encontró un usuario para esa identificación."
        );
        return;
      }
      // Extra: Validation for only CF users
      if (data.results.length > 1) {
        data.results = data.results.filter((item) => item.position === null);
      }
      const user = data.results[0];
      const identification_type_name =
        this.$store.state.form.optionsIdentificationType.find(
          (item) => item.id == user.identification_type
        )?.name;

      this.customer = {
        identification_type: user.identification_type,
        identification_number: user.identification_number,
        birth_date: user.birth_date,
        person_type: user.person_type,
        first_name: user.user_id.first_name,
        last_name: user.user_id.last_name,
        phone: user.user_id.phone,
        email: user.user_id.email,
        user_id: user.user_id?.id,
        identification_type_name,
        business_name: user.user_id?.business_name,
        departamento_cod: user.user_id?.departamento_cod,
        municipio_cod: user.user_id?.municipio_cod,
        address: user.user_id?.address,
        email_notification: user.user_id?.email_notification, 
      };
    }
  },
  computed: {
    ...mapState({
      formOptions: (state) => {
        return state.form;
      },
    }),
  },
  methods: {
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    authorizePersonalDataProcessing() {
      if (this.checkPersonalData != 1) {
        // this.showToast("error","Debes aceptar el tratamiento de datos personales antes de continuar")
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      } else {
        this.personalDataAuthorized = true;
        this.modalInfoVisible = false;
      }
    },
    onCancelModal() {
      this.$router.go(-2);
    },
  },
  watch: {
    // modalInfoVisible(newValue) {
    //   if (!newValue && !this.personalDataAuthorized) {
    //     this.showToast(
    //       "error",
    //       "Debes aceptar el tratamiento de datos personales antes de continuar"
    //     );
    //     this.modalInfoVisible = true;
    //   }
    // },
    checkPersonalData(newValue) {
      if (newValue != 1) {
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      }else{
        this.feedback = ""
      }
    },
  },
};
</script>